var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "nv-fs-overlay d-flex align-center justify-center" },
    [
      _c(
        "div",
        { staticClass: "d-flex flex-column align-center justify-center" },
        [
          _c("v-img", {
            attrs: {
              src: require("@/assets/Nuvolos-transparent.svg"),
              alt: "Loading Nuvolos",
              width: "150",
            },
          }),
          _c("v-progress-circular", {
            staticClass: "mt-5",
            attrs: {
              indeterminate: "",
              color: "primary",
              size: "24",
              width: "2",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }